import { startOfToday, subDays, subSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import PingSound from "./assets/message-ping.mp3";
import {
  useClearNotificationsMutation,
  useDeleteNotificationMutation,
  useMarkNotificationsReadMutation,
} from "generated/graphql";
import { apiClient } from "services/apiClient";
import { MentionedUser } from "components/forms/mentions-field/RenderMentions";
import { useToast } from "@chakra-ui/react";

export interface Notification {
  id: string;
  body: string;
  imageUrl?: string | null;
  link: string;
  read: boolean;
  title: string;
  createdAt: Date;
}

interface NotificationsProviderProps {
  children: React.ReactNode;
}

interface NotificationsContextType {
  notifications: Notification[];
  mentionedUsers: MentionedUser[];
  setNotificationsViewed: () => void;
  deleteNotification: (notificationId: string) => void;
  clearNotifications: () => void;
}

export const NotificationsContext =
  React.createContext<NotificationsContextType>({
    notifications: [],
    mentionedUsers: [],
    setNotificationsViewed: () => undefined,
    deleteNotification: () => undefined,
    clearNotifications: () => undefined,
  });

const getNotifications = async (
  after?: Date
): Promise<{
  mentionedUsers: MentionedUser[];
  notifications: {
    body: string;
    createdAt: Date;
    id: string;
    imageUrl?: string | null;
    link: string;
    read: boolean;
    title: string;
  }[];
}> => {
  const notifications = await apiClient.auth.notifications({
    body: {
      after,
    },
  });

  if (notifications.status !== 200) {
    throw new Error("Failed to get notifications");
  }

  try {
    return {
      notifications: notifications.body.notifications.map((notification) => ({
        ...notification,
        createdAt: new Date(notification.createdAt),
      })),
      mentionedUsers: notifications.body.mentionedUsers.map((user) => ({
        id: user.id,
        name: user.name,
        imageUrl: user.imageUrl,
        bio: user.bio,
        lastOnlineAt: new Date(user.lastOnlineAt),
        timezone: user.timezone,
      })),
    };
  } catch (error) {
    throw new Error("Failed to get notifications");
  }
};

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  const { id: userId } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [mentionedUsers, setMentionedUsers] = useState<MentionedUser[]>([]);
  const { mutateAsync: deleteNotificationMutation } =
    useDeleteNotificationMutation();
  const { mutateAsync: markNotificationsRead } =
    useMarkNotificationsReadMutation();
  const { mutateAsync: clearNotificationsMutation } =
    useClearNotificationsMutation();

  useEffect(() => {
    getNotifications(subDays(startOfToday(), 60)).then(
      (res) => {
        setMentionedUsers(res.mentionedUsers);
        setNotifications(
          res.notifications.sort(
            (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
          )
        );
      },
      (rej) => console.error({ title: rej.message, status: "error" })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const toast = useToast();

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotifications(subSeconds(new Date(), 5)).then(
        (res) => {
          if (res.notifications.length) {
            new Audio(PingSound).play();

            for (const notification of res.notifications) {
              toast({
                title: notification.title,
                description: notification.body,
                status: "info",
                position: "top-right",
                duration: 20000,
                isClosable: true,
              });
            }
          }
          setMentionedUsers((prevMentioned) => [
            ...res.mentionedUsers,
            ...prevMentioned,
          ]);
          setNotifications((prevNotifications) => [
            ...res.notifications.map((notification) => ({
              ...notification,
              createdAt: new Date(notification.createdAt),
            })),
            ...prevNotifications,
          ]);
        },
        (rej) => console.error({ title: rej.message, status: "error" })
      );
    }, 5000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNotificationsViewed = () => {
    setNotifications(
      notifications.map((notification) => ({ ...notification, read: true }))
    );
    markNotificationsRead({});
  };

  const deleteNotification = async (notificationId: string) => {
    setNotifications(notifications.filter((n) => n.id !== notificationId));
    await deleteNotificationMutation({ id: notificationId });
  };

  const clearNotifications = async () => {
    setNotifications([]);
    await clearNotificationsMutation({});
  };

  const notificationContextData: NotificationsContextType = {
    notifications,
    mentionedUsers,
    setNotificationsViewed,
    deleteNotification,
    clearNotifications,
  };

  return (
    <NotificationsContext.Provider value={notificationContextData}>
      {children}
    </NotificationsContext.Provider>
  );
};
