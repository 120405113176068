let endpoint = process.env.REACT_APP_BASE_DOMAIN;

if (process.env.NODE_ENV === "production") {
  endpoint = "https://" + endpoint;
} else {
  endpoint = "http://" + endpoint;
}

export const frontendUrl = window.location.origin;
export const backendUrl = endpoint;
export const workspaceId =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_LOCAL_BACKEND
    : window.location.host.split(".")[0];
