import { initContract } from "@ts-rest/core";
import { z } from "zod";

const c = initContract();

export const UserSchema = z.object({
  email: z.string().email("Invalid email address."),
  name: z.string(),
});

const ClientSchema = z.object({
  name: z.string(),
  tags: z.string().array().min(1, "The client must have at least one tag."),
  service: z.string(),
  boilerplate: z.string(),
  logo: z.string().optional(),
  location: z.object({
    locality: z.string(),
    country: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    streetAddress: z.string(),
    timezone: z.string(),
  }),
  agencyManagers: UserSchema.array(),
  employees: UserSchema.array(),
  spokespeople: z
    .object({
      content: z.string(),
      name: z.string(),
      title: z.string(),
      image: z.string().optional(),
    })
    .array(),
});

export const signupContract = c.router({
  createWorkspace: {
    method: "POST",
    path: "/create-workspace",
    body: z.object({
      workspace: z.object({
        name: z.string().max(50),
      }),
      user: UserSchema,
      team: UserSchema.array(),
      clients: ClientSchema.array(),
    }),
    responses: {
      200: z.object({
        workspaceId: z.string(),
        loginLink: z.string(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
  validateWorkspace: {
    method: "POST",
    path: "/validate-workspace",
    body: z.object({
      workspace: z.string(),
    }),
    responses: {
      200: z.object({
        workspace: z
          .object({
            name: z.string().max(50),
            id: z.string(),
          })
          .optional(),
        exists: z.boolean(),
      }),
    },
  },
  validateEmail: {
    method: "POST",
    path: "/validate-email",
    body: z.object({
      email: z.string(),
    }),
    responses: {
      200: z.object({
        workspace: z
          .object({
            name: z.string().max(50),
            id: z.string(),
            logo: z.string().nullable().optional(),
          })
          .optional(),
        exists: z.boolean(),
      }),
    },
  },
});
