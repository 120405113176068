import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface DemoProviderProps {
  children: React.ReactNode;
}

export const DemoProvider = ({ children }: DemoProviderProps) => {
  return (
    <>
      <div>{children}</div>
      <Modal isOpen={false} onClose={() => undefined}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Welcome to Yaarn!</ModalHeader>
          <ModalBody></ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
