import {
  Avatar,
  Button,
  Flex,
  ListItem,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { ChatbotMarkdown } from "./ChatbotMarkdown";
import botLogo from "./bot-logo.png";

interface ProvisionalChatbotMessageProps {
  message: string;
  onStopGenerating: () => void;
}

export const ProvisionalChatbotMessage = ({
  message,
  onStopGenerating,
}: ProvisionalChatbotMessageProps) => {
  return (
    <ListItem backgroundColor={"gray.200"}>
      <Flex justify="space-between" py="3" align="center">
        <Flex w="100%">
          <Flex flexGrow="0" mx="4">
            <Tooltip label="Yaarn Bot">
              <Avatar size="sm" name="Yaarn Bot" src={botLogo} />
            </Tooltip>
          </Flex>
          <Flex align="center">{message === "" && <Spinner size="sm" />}</Flex>
          <ChatbotMarkdown message={message} />
        </Flex>
        <Flex justify="center" flexGrow={0} h="100%" w="6em" alignItems="start">
          <Button
            ml="2"
            size="xs"
            color="gray.500"
            textAlign="right"
            variant="ghost"
            onClick={onStopGenerating}
          >
            Stop generating
          </Button>
        </Flex>
      </Flex>
    </ListItem>
  );
};
