import { apiClient } from "./apiClient";

export const requestSupportEmail = async (
  email: string,
  name: string,
  message: string
) => {
  const result = await apiClient.misc.support({
    body: {
      email,
      name,
      message,
    },
  });

  if (result.status !== 200) {
    throw new Error("Failed to send support email");
  }

  return result;
};
