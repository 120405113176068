import { ChatbotMessageType } from "providers/chatbot/ChatbotDrawer";
import { apiClient } from "./apiClient";

type OnStream = (data: { text: string; isComplete: boolean }) => void;

type StreamAIResponseParams = {
  onStream: OnStream;
} & Parameters<typeof apiClient.ai.generatePiece>[0]["body"];

export const streamAIResponse = async ({
  onStream,
  ...rest
}: StreamAIResponseParams) => {
  const result = await apiClient.ai.generatePiece({
    body: rest,
  });

  if (!(result.status === 200)) {
    throw new Error("Failed to generate piece");
  }

  const reader = result.body;

  (async () => {
    let shouldContinue = true;
    while (shouldContinue) {
      if (!reader) {
        throw new Error("No reader found");
      }

      const decoder = new TextDecoder("utf-8");
      const { value: chunk, done: readerDone } = await reader.read();

      if (readerDone) {
        onStream({ text: "", isComplete: true });
        shouldContinue = false;
      }

      const chunkString = decoder.decode(chunk);
      onStream({ text: chunkString, isComplete: false });
    }
  })();

  return reader;
};

interface StreamChatProps {
  onStream: OnStream;
  bodyData: ChatbotMessageType[];
}

export const streamChat = async ({ onStream, bodyData }: StreamChatProps) => {
  const result = await apiClient.ai.chat({
    body: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      history: bodyData.map((x) => ({
        role: x.from,
        content: x.message,
      })),
    },
  });

  if (!(result.status === 200)) {
    throw new Error("Failed to generate piece");
  }

  const reader = result.body;

  (async () => {
    let shouldContinue = true;
    while (shouldContinue) {
      if (!reader) {
        throw new Error("No reader found");
      }

      const decoder = new TextDecoder("utf-8");
      const { value: chunk, done: readerDone } = await reader.read();

      if (readerDone) {
        onStream({ text: "", isComplete: true });
        shouldContinue = false;
      } else {
        const chunkString = decoder.decode(chunk);
        onStream({ text: chunkString, isComplete: false });
      }
    }
  })();

  return reader;
};
