import { initClient } from "@ts-rest/core";
import { contract } from "./contract";
import { backendUrl } from "./getBackendUrl";

export const client = (authToken?: string) => {
  const baseHeaders = authToken ? { Authorization: authToken } : ({} as {});

  return initClient(contract, {
    baseUrl: backendUrl,
    baseHeaders,
    api: async (args) => {
      const response = await fetch(args.path, {
        method: args.method,
        headers: args.headers,
        body: args.body,
      });

      if (response.status === 204) {
        return {
          status: 204,
          body: null,
          headers: response.headers,
        };
      }

      if (response.status === 200) {
        if ((args.route.metadata as { streams: boolean })?.streams) {
          return {
            status: 200,
            body: response.body?.getReader(),
            headers: response.headers,
          };
        } else if ((args.route.metadata as { blob: boolean })?.blob) {
          return {
            status: 200,
            body: response.blob(),
            headers: response.headers,
          };
        } else {
          const json = await response.json();
          return {
            status: response.status,
            body: json,
            headers: response.headers,
          };
        }
      } else {
        const json = await response.json();
        return {
          status: response.status,
          body: json,
          headers: response.headers,
        };
      }
    },
  });
};
