import {
  Popover,
  PopoverTrigger,
  Avatar,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  Flex,
  AvatarBadge,
} from "@chakra-ui/react";
import { differenceInMinutes } from "date-fns";
import { useGetTimezoneOffset } from "../../hooks/useGetTimezoneOffset";

interface UserPopoverProps {
  author: string;
  bio: string;
  lastOnlineAt: Date;
  timezone: string;
  image?: string | null;
  showMainActiveIndicator?: boolean;
  children: React.ReactNode;
}

export const UserPopover = ({
  author,
  lastOnlineAt,
  timezone,
  image,
  bio,
  children,
}: UserPopoverProps) => {
  const { TimezoneOffset } = useGetTimezoneOffset();

  const badgeColour = (() => {
    if (Math.abs(differenceInMinutes(new Date(lastOnlineAt), new Date())) < 5) {
      return "green.600";
    } else if (
      Math.abs(differenceInMinutes(new Date(lastOnlineAt), new Date())) < 30
    ) {
      return "yellow.400";
    } else {
      return "red.400";
    }
  })();

  return (
    <Popover isLazy trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">
          <Flex justifyContent="space-between">
            <Flex alignItems="center">
              <Avatar
                size="sm"
                src={image ? process.env.REACT_APP_FILE_URL + image : ""}
                name={author}
                mr="3"
              >
                <AvatarBadge
                  borderColor="papayawhip"
                  bg={badgeColour}
                  boxSize="1.25em"
                />
              </Avatar>
              {author}
            </Flex>
            <Flex
              textAlign="center"
              fontWeight="bold"
              color="gray.600"
              fontSize="xs"
              flexDirection="column"
            >
              <TimezoneOffset short timezone={timezone} />
            </Flex>
          </Flex>
        </PopoverHeader>
        <PopoverArrow />
        {bio !== "" && <PopoverBody>{bio}</PopoverBody>}
      </PopoverContent>
    </Popover>
  );
};
