import { Flex, Spinner } from "@chakra-ui/react";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { NotFound } from "./components/page-shell/NotFound";
import { useAuth } from "./hooks/useAuth";
import { DefaultVisibility, routesWithPermissions } from "./routes";

export const App = () => {
  const { hasPermission } = useAuth();

  return (
    <Suspense
      fallback={
        <Flex h="100vh" w="100vw" justify="center" align="center">
          <Spinner size="xl" />
        </Flex>
      }
    >
      <Routes>
        {Object.entries(routesWithPermissions).map((route) => {
          const Element = route[1].element;
          return (
            (route[1].permission === DefaultVisibility.VISIBLE_TO_ANYONE ||
              hasPermission(route[1].permission)) && (
              <Route key={route[0]} path={route[0]} element={<Element />} />
            )
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
