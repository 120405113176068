import { ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Flex, Textarea } from "@chakra-ui/react";
import { useRef, useState } from "react";

interface ExpandingTextareaInputProps {
  placeholder: string;
  onSubmit: (value: string) => void;
  isGenerating?: boolean;
}

export const ExpandingTextareaInput = ({
  placeholder,
  onSubmit,
  isGenerating = false,
}: ExpandingTextareaInputProps) => {
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const lineHeight = 20;

  //Expands the textarea vertically as the user types, accounting for word wrap
  const getHeight = () => {
    return value.split("\n").length;
  };

  return (
    <Flex
      shadow="md"
      mx="2"
      border="1px solid #e6e6e6"
      borderRadius="md"
      backgroundColor="white"
      mt="2"
      mb="2"
      align="end"
      cursor="text"
      onClick={() => inputRef.current?.focus()}
    >
      <Textarea
        placeholder={placeholder}
        alignSelf="start"
        _focus={{
          outline: "none !important",
          boxShadow: "none !important",
        }}
        border="none"
        ml="2"
        value={value}
        ref={inputRef}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
            onSubmit(value);
            setValue("");
          }
        }}
        lineHeight={lineHeight + "px"}
        h="auto"
        rows={getHeight()}
        resize={"none"}
      />
      <Button
        cursor="pointer"
        my="2"
        mx="2"
        isDisabled={value.length === 0}
        colorScheme="blue"
        isLoading={isGenerating}
        onClick={() => {
          onSubmit(value);
          setValue("");
        }}
      >
        <ChevronRightIcon />
      </Button>
    </Flex>
  );
};
