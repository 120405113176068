import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as StepsTheme } from "chakra-ui-steps";

const theme = extendTheme({
  colors: {
    yaarnRed: {
      100: "#fbdedd",
      200: "#f6bdba",
      300: "#f39d9a",
      400: "#ef7d78",
      500: "#ec5c56",
      600: "#df4842",
      700: "#d13a33",
      800: "#c02a24",
      900: "#a01e19",
    },
    yaarnCream: {
      100: "#fffefc",
      200: "#fff5ed",
      300: "#fff0e6",
      400: "#ffecdd",
      500: "#ffe8d5",
    },
    yaarnYellow: {
      100: "#ffe8d5",
      200: "#ffd9a4",
      300: "#ffc576",
      400: "#ffb349",
      500: "#ffa01a",
    },
  },
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Nunito', sans-serif`,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  styles: {
    global: {
      "h1,h2,h3,h4,h5,h6": {
        color: "yaarnRed.600",
      },
      "ul,ol": {
        listStylePosition: "inside",
      },
      th: {
        fontWeight: "100 !important",
      },
      td: {
        paddingTop: "2px !important",
        paddingBottom: "2px !important",
      },
      body: {
        bg: "yaarnCream.50",
      },
    },
  },
  components: {
    Steps: StepsTheme,
  },
});

export default theme;
