import { Container, Link, Text } from "@chakra-ui/react";

import { PageShell } from "./PageShell";

export const NotFound = () => {
  return (
    <PageShell title="Resource not found">
      <Container my="12">
        <Text fontWeight="bold" fontSize="8xl">
          Error 404.
        </Text>
        <Text fontSize="2xl">Could not find this resource.</Text>
        <Text mt="4">
          Need help? Contact us at{" "}
          <Link href="mailto:help@yaarn.io">help@yaarn.ai</Link>.
        </Text>
      </Container>
    </PageShell>
  );
};
