import { initContract } from "@ts-rest/core";
import { ZodType, z, infer } from "zod";
import { exportFilterOptions } from "../validation/CoverageTrackerFilterType";

const c = initContract();

export const miscContract = c.router({
  health: {
    method: "GET",
    path: "/health",
    responses: {
      200: z.object({
        uptime: z.number(),
        at: z.number(),
        databaseAccessible: z.boolean(),
      }),
    },
  },
  // Returns a PDF file with the coverage report
  generateCoverageReport: {
    method: "POST",
    path: "/coverage-report/pdf/:clientId",
    body: exportFilterOptions,
    metadata: {
      blob: true,
    },
    responses: {
      200: c.type<Promise<Blob>>(),
      401: z.object({
        message: z.string(),
        success: z.boolean(),
      }),
    },
  },
  exportToXlsx: {
    method: "POST",
    path: "/coverage-report/xlsx/:clientId",
    body: exportFilterOptions,
    metadata: {
      blob: true,
    },
    responses: {
      200: c.type<Promise<Blob>>(),
      401: z.object({
        message: z.string(),
        success: z.boolean(),
      }),
    },
  },
  exportToCsv: {
    method: "POST",
    path: "/coverage-report/csv/:clientId",
    body: exportFilterOptions,
    metadata: {
      blob: true,
    },
    responses: {
      200: c.type<Promise<Blob>>(),
      401: z.object({
        message: z.string(),
        success: z.boolean(),
      }),
    },
  },
  support: {
    method: "POST",
    path: "/support",
    body: z.object({
      name: z.string(),
      email: z.string().email(),
      message: z.string(),
    }),
    responses: {
      200: z.object({}),
      400: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
    },
  },
});
