import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface TimezoneOffsetProps {
  timezone: string;
  /**
   * Whether to display the text in a shorter form
   */
  short?: boolean;
}

export const useGetTimezoneOffset = () => {
  const getOffset = (timezone = "UTC", date = new Date()) => {
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(
      date.toLocaleString("en-US", { timeZone: timezone })
    );
    return (tzDate.getTime() - utcDate.getTime()) / 60000;
  };

  const getCurrentTimeByTz = (timezone = "UTC", short?: boolean) => {
    const formatter = new Intl.DateTimeFormat([], {
      timeZone: timezone as string,
      month: short ? "2-digit" : "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });

    return formatter.format(new Date());
  };

  const TimezoneOffset = ({ timezone, short }: TimezoneOffsetProps) => {
    const [currentTime, setCurrentTime] = useState(
      getCurrentTimeByTz(timezone, short)
    );

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(getCurrentTimeByTz(timezone, short));
      });

      return () => clearInterval(timer);
    });

    return (
      <>
        <Text fontWeight="bold">
          GMT {getOffset(timezone?.toString()) / 60 >= 0 && "+"}
          {getOffset(timezone?.toString()) / 60}
        </Text>
        ({currentTime})
      </>
    );
  };

  return {
    getOffset,
    getCurrentTimeByTz,
    TimezoneOffset,
  };
};
