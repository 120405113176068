import {
  Box,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  AuthenticatedUser,
  AuthenticatedUserContext,
} from "./AuthenticatedUserContext";
import { LoginCard } from "../components/page-shell/LoginCard";
import { workspaceId } from "../services/getBackendUrl";
import { differenceInDays } from "date-fns";
import { apiClient } from "services/apiClient";

interface AuthProviderProps {
  children: React.ReactNode;
}

const validateWorkspace = async () => {
  const res = await apiClient.signup.validateWorkspace({
    body: {
      workspace: workspaceId,
    },
  });

  if (res.status !== 200) {
    return false;
  }

  return true;
};

export const AuthenticatedProvider = ({
  children,
}: AuthProviderProps): JSX.Element => {
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true);
  const [isWorkspaceValid, setIsWorkspaceValid] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as AuthenticatedUser);
  const toast = useToast();

  useEffect(() => {
    validateWorkspace().then((isValid) => {
      setIsWorkspaceValid(isValid);
      setIsLoadingWorkspace(false);
    });
  }, []);

  const authenticate = async (
    token: string,
    timezone: string,
    workspace: string
  ) => {
    const result = await apiClient.auth.authenticate({
      body: {
        token,
        timezone,
        workspace,
      },
    });

    try {
      if (result.status === 200) {
        const data = result.body;

        setIsLoadingAuth(false);

        if (data.user.isArchived) {
          setIsAuthenticated(false);
          window.localStorage.clear();
          toast({
            title: "Your account has been archived.",
            description:
              "Please contact your administrator to reactivate your account.",
            status: "error",
          });
          return;
        } else {
          setIsAuthenticated(true);
          setCurrentUser(data.user);
        }
      } else if (result.status === 401) {
        setIsLoadingAuth(false);
        setIsAuthenticated(false);
        toast({
          title: "Error connecting to API.",
          description: result.body.message,
          status: "error",
        });
        window.localStorage.clear();
      } else {
        toast({
          title: "Error connecting to API.",
          description: "Please try again later.",
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: "Error connecting to API.",
        description: (error as Error).message,
        status: "error",
      });
    }
  };

  useEffect(() => {
    const searchParamsToken = new URLSearchParams(window.location.search).get(
      "token"
    );

    if (searchParamsToken && window.location.pathname === "/login/") {
      window.localStorage.setItem("authToken", searchParamsToken);
      window.location.replace("/");
    }

    const checkToken = async () => {
      const authToken = window.localStorage.getItem("authToken");
      const timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone ??
        "Australia/Melbourne";

      if (authToken === null) {
        setIsAuthenticated(false);
        setIsLoadingAuth(false);
      } else {
        setIsLoadingAuth(true);
        authenticate(authToken, timezone, workspaceId);
      }
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.key === "authToken" ||
        (!event.key && !window.localStorage.getItem("authToken"))
      ) {
        checkToken();
      }
    };

    checkToken();
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoadingWorkspace && !isWorkspaceValid) {
    return (
      <Flex minH="100vh" justifyContent="center" alignItems="center">
        <Box>
          <Heading color="gray.700" fontSize="6xl">
            Invalid Yaarn workspace.
          </Heading>
          <Link href="https://login.yaarn.ai" mt="8">
            Go to login page
          </Link>
        </Box>
      </Flex>
    );
  }

  if (isLoadingAuth || isLoadingWorkspace) {
    return (
      <Flex minH="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (isAuthenticated) {
    // If workspace created more than 14 days ago and user is not subscribed
    // to a paid plan, show modal
    const workspaceCreatedAt = new Date(currentUser?.workspace?.createdAt);
    const today = new Date();
    const daysSinceWorkspaceCreation = differenceInDays(
      today,
      workspaceCreatedAt
    );
    return (
      <AuthenticatedUserContext.Provider value={currentUser}>
        {children}
        {daysSinceWorkspaceCreation > 14 &&
          !currentUser.workspace.hasPaidSubscription && (
            <Modal onClose={() => undefined} isOpen={true}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Trial expired</ModalHeader>
                <ModalBody>
                  Your trial has expired.
                  <br />
                  Please contact us at{" "}
                  <Link
                    color="blue"
                    textDecor="underline"
                    href="mailto:contact@yaarn.ai"
                  >
                    contact@yaarn.ai
                  </Link>{" "}
                  to continue using Yaarn.
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
      </AuthenticatedUserContext.Provider>
    );
  }

  if (!isAuthenticated) {
    return (
      <Flex minH="100vh" justifyContent="center" alignItems="center">
        <LoginCard />
      </Flex>
    );
  }

  return <></>;
};
