import React, { useEffect } from "react";
import { useState } from "react";

interface VersionContextProps {
  children: React.ReactNode;
}

interface VersionContextType {
  version: string;
}

export const VersionContext = React.createContext<VersionContextType>({
  version: "",
});

export const VersionProvider = ({ children }: VersionContextProps) => {
  const [version, setVersion] = useState("0.0.0");

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/version.json")
      .then((response) => response.json())
      .then((data) => {
        setVersion(data.version);
      });
  }, []);

  // check the version every 10 seconds and reload if it doesn't match
  useEffect(() => {
    const interval = setInterval(() => {
      fetch(process.env.PUBLIC_URL + "/version.json")
        .then((response) => response.json())
        .then((data) => {
          if (data.version !== version) {
            window.location.reload();
          }
        });
    }, 10000);
    return () => clearInterval(interval);
  }, [version]);

  return (
    <VersionContext.Provider
      value={{
        version,
      }}
    >
      {children}
    </VersionContext.Provider>
  );
};
