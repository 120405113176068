import { PermissionEnum } from "./generated/graphql";
import { lazyWithPreload, PreloadableComponent } from "react-lazy-with-preload";

export enum DefaultVisibility {
  VISIBLE_TO_ANYONE = "VISIBLE_TO_ANYONE",
}

const agencyRoutes = {
  "/agency-dashboard": {
    element: lazyWithPreload(
      () => import("pages/agency/dashboard/AgencyDashboard")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/authors": {
    element: lazyWithPreload(() => import("pages/agency/authors/Authors")),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/authors/:id/edit": {
    element: lazyWithPreload(() => import("pages/agency/authors/AuthorEdit")),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/program-planner/client/:clientId": {
    element: lazyWithPreload(
      () => import("pages/agency/program-planner/ProgramPlanner")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/authors/:id": {
    element: lazyWithPreload(() => import("pages/agency/authors/Author")),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/competitor-analysis/client/:clientId": {
    element: lazyWithPreload(
      () => import("pages/agency/competitor-analysis/CompetitorAnalysis")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/competitors/:id/edit": {
    element: lazyWithPreload(
      () => import("pages/agency/competitor-analysis/CompetitorSettings")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/activities/client/:clientId": {
    element: lazyWithPreload(
      () => import("pages/agency/activities/Activities")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/content-writer": {
    element: lazyWithPreload(
      () => import("pages/agency/content-writer/ContentWriterRecent")
    ),
    permission: PermissionEnum.CanViewAgencyDashboard,
  },
  "/clients/:id": {
    element: lazyWithPreload(() => import("pages/agency/client/Client")),
    permission: PermissionEnum.CanViewAllClients,
  },
  "/clients": {
    element: lazyWithPreload(
      () => import("pages/agency/client-settings/ClientSettings")
    ),
    permission: PermissionEnum.CanEditClients,
  },
  "/clients/:id/edit": {
    element: lazyWithPreload(() => import("pages/agency/client/ClientEdit")),
    permission: PermissionEnum.CanEditClients,
  },
  "/publications/:id": {
    element: lazyWithPreload(
      () => import("pages/agency/publications/Publication")
    ),
    permission: PermissionEnum.CanViewPublications,
  },
  "/publications": {
    element: lazyWithPreload(
      () => import("pages/agency/publications/Publications")
    ),
    permission: PermissionEnum.CanViewPublications,
  },
  "/coverage-tracker/clients/:id": {
    element: lazyWithPreload(
      () => import("pages/agency/coverage-tracker/CoverageTracker")
    ),
    permission: PermissionEnum.CanAddCoverage,
  },
  "/users": {
    element: lazyWithPreload(() => import("pages/agency/users/Users")),
    permission: PermissionEnum.CanViewAllUsers,
  },
  "/roles": {
    element: lazyWithPreload(() => import("pages/agency/Roles")),
    permission: PermissionEnum.CanViewAllUsers,
  },
  "/news-wraps": {
    element: lazyWithPreload(() => import("pages/agency/news-wraps/NewsWraps")),
    permission: PermissionEnum.CanViewNewsWraps,
  },
  "/news-wraps/:id": {
    element: lazyWithPreload(() => import("pages/agency/news-wraps/NewsWrap")),
    permission: PermissionEnum.CanViewNewsWraps,
  },
  "/workspace": {
    element: lazyWithPreload(
      () => import("pages/agency/workspace/WorkspaceSettings")
    ),
    permission: PermissionEnum.CanEditWorkspace,
  },
};

const clientRoutes = {
  "/client-dashboard": {
    element: lazyWithPreload(
      () => import("pages/client/dashboard/ClientDashboard")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
  },
  "/client/coverage": {
    element: lazyWithPreload(() => import("pages/client/coverage/Coverage")),
    permission: PermissionEnum.CanViewClientDashboard,
  },
  "/client/activities": {
    element: lazyWithPreload(
      () => import("pages/client/activities/Activities")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
  },
  "/client/settings": {
    element: lazyWithPreload(
      () => import("pages/client/settings/ClientSettings")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
  },
  "/client/content-writer": {
    element: lazyWithPreload(
      () => import("pages/client/content-writer/ClientContentWriter")
    ),
    permission: PermissionEnum.CanViewClientDashboard,
  },
};

export const routesWithPermissions: {
  [key: string]: {
    element: PreloadableComponent<() => JSX.Element>;
    permission: PermissionEnum | DefaultVisibility;
  };
} = {
  "/": {
    element: lazyWithPreload(() => import("pages/shared/DashboardRedirect")),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  "/chat": {
    element: lazyWithPreload(() => import("pages/agency/Chat")),
    permission: PermissionEnum.CanViewChatPage,
  },
  "/chat/:id": {
    element: lazyWithPreload(() => import("pages/agency/Chat")),
    permission: PermissionEnum.CanViewClientDashboard,
  },

  "/profile": {
    element: lazyWithPreload(() => import("pages/shared/Profile")),
    permission: PermissionEnum.CanViewSettings,
  },
  "/content-writer/:id": {
    element: lazyWithPreload(
      () => import("pages/agency/content-writer/ContentWriter")
    ),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  "/news-search": {
    element: lazyWithPreload(
      () => import("pages/shared/news-search/NewsSearch")
    ),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  "/news-search/similar/:articleId": {
    element: lazyWithPreload(
      () => import("pages/shared/news-search/SimilarArticles")
    ),
    permission: DefaultVisibility.VISIBLE_TO_ANYONE,
  },
  ...agencyRoutes,
  ...clientRoutes,
};
