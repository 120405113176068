import { backendUrl } from "../services/getBackendUrl";

export function graphqlFetcher<TData, TVariables>(
  query: string,
  variables?: TVariables
) {
  return async (): Promise<TData> => {
    const authToken = window.localStorage.getItem("authToken");

    if (!authToken) {
      throw new Error("No authorization token provided for GraphQl.");
    }

    const res = await fetch(backendUrl + "/graphql", {
      method: "POST",
      ...{
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
