import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  Popover,
  Button,
  PopoverTrigger,
  PopoverContent,
  List,
  PopoverArrow,
} from "@chakra-ui/react";
import { FaBook, FaCommentDots, FaList } from "react-icons/fa";
import { SupportModal } from "./SupportModal";
import { useAuth } from "hooks/useAuth";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { SidebarLink } from "./SidebarLink";
import { MdHelp } from "react-icons/md";

interface SidebarLinkProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  fontSize?: string;
}

export const SidebarSupportMenu = ({ fontSize }: SidebarLinkProps) => {
  const { name, email } = useAuth();
  return (
    <ListItem my="1" mx="4">
      <Popover placement="right">
        <PopoverTrigger>
          <Button
            color="white"
            paddingStart={3}
            paddingEnd={3}
            fontWeight={"regular !important"}
            backgroundColor="yaarnRed.500"
            _hover={{ backgroundColor: "yaarnRed.400 !important" }}
            borderRadius="md"
            w="100%"
          >
            <Box w="100%" fontSize={fontSize} transition="ease 400ms all">
              <Flex justify="space-between" align="center">
                <Flex>
                  <Box mr={3} display="inline">
                    <Icon fontSize="sm" as={MdHelp} />
                  </Box>
                  <Box display="inline">
                    <Text>Support</Text>
                  </Box>
                </Flex>
                <ChevronRightIcon />
              </Flex>
            </Box>
          </Button>
        </PopoverTrigger>
        <PopoverContent backgroundColor="yaarnRed.500" w="200px">
          <PopoverArrow />
          <List p={0}>
            <SidebarLink
              mx={2}
              py={2}
              key={`https://docs.yaarn.ai`}
              icon={FaBook}
              to={`https://docs.yaarn.ai`}
              text="Documentation"
              fontSize="xs"
            />
            <SidebarLink
              mx={2}
              py={2}
              key={`https://forms.gle/pNUdHbr447RPVrv16`}
              icon={FaCommentDots}
              to={`https://forms.gle/pNUdHbr447RPVrv16`}
              text="Feedback"
              fontSize="xs"
            />
            <SupportModal
              initialValues={{
                name,
                email,
              }}
            >
              <SidebarLink
                onClick={(e) => {
                  e.preventDefault();
                }}
                mx={2}
                py={2}
                key={`/supportModal`}
                icon={FaList}
                to={`#`}
                text="Support"
                fontSize="xs"
              />
            </SupportModal>
          </List>
        </PopoverContent>
      </Popover>
    </ListItem>
  );
};
