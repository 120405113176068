import { useContext } from "react";
import { DefaultVisibility } from "routes";
import { AuthenticatedUserContext } from "../providers/AuthenticatedUserContext";
import { PermissionEnum } from "../generated/graphql";
import { workspaceId } from "../services/getBackendUrl";
import { apiClient } from "services/apiClient";

export const useAuth = () => {
  const authenticatedUser = useContext(AuthenticatedUserContext);

  const hasPermission = (
    permission: PermissionEnum | DefaultVisibility
  ): boolean => {
    if (permission === DefaultVisibility.VISIBLE_TO_ANYONE) {
      return true;
    }
    return authenticatedUser.role.permissions.includes(permission);
  };

  const requestLogIn = async (email: string) => {
    const result = await apiClient.auth.requestLogin({
      body: {
        email,
        workspace: workspaceId,
      },
    });

    return result;
  };

  const logOut = async () => {
    await apiClient.auth.logout();

    window.localStorage.clear();
    window.dispatchEvent(new Event("storage"));
  };

  return {
    ...authenticatedUser,
    hasPermission,
    requestLogIn,
    logOut,
  };
};
