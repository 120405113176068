import { ArrowForwardIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Container,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { FormControl, SubmitButton } from "formik-chakra-ui";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Card } from "../Card";
import * as Yup from "yup";
import Logo from "./assets/logo-red-transparent-1024.png";
import { SupportModal } from "./SupportModal";

const initialValues = {
  email: "",
};

export const LoginCard = () => {
  const [hasRequestedLogin, setHasRequestedLogin] = useState(false);
  const { requestLogIn } = useAuth();
  const toast = useToast();

  const onLoginSubmit = async (values: typeof initialValues) => {
    const result = await requestLogIn(values.email);
    if (result.status === 200) {
      setHasRequestedLogin(true);
    } else if (result.status === 400) {
      toast({
        title: "Error logging in",
        description: result.body.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error logging in",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("You must enter a valid email.")
      .required("You must enter an email address."),
  });

  const hasLoginError = window.location.search.includes("status=error");

  return (
    <Card>
      <Flex justifyContent="center">
        <Image w="96" src={Logo} />
      </Flex>
      <Divider />
      <Container mt={8}>
        {!hasRequestedLogin ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onLoginSubmit}
          >
            {({ handleSubmit, errors, values, setFieldValue }) => (
              <Box as="form" onSubmit={handleSubmit as any}>
                <FormControl name="email" isInvalid={!!errors.email}>
                  <InputGroup>
                    <InputLeftAddon>
                      <EmailIcon />
                    </InputLeftAddon>
                    <Input
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="off"
                      inputMode="email"
                      title="Email"
                      autoFocus
                      value={values.email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const emailWithoutInvalidChars =
                          event.target.value.replace(/[()<>[\]:;,]/g, "");
                        setFieldValue(
                          "email",
                          emailWithoutInvalidChars.replaceAll(" ", "")
                        );
                      }}
                      name="email"
                      placeholder="john.smith@email.com"
                    />
                  </InputGroup>
                </FormControl>

                <Box backgroundColor="gray.50" borderRadius={5} p={5} mt={5}>
                  We&apos;ll send you an email with a link that you can use to
                  log in.
                </Box>
                <SubmitButton colorScheme="yaarnRed" mt={6} w="100%">
                  <ArrowForwardIcon me={3} />
                  Log in with email
                </SubmitButton>

                {hasLoginError && (
                  <Alert mt="3" borderRadius="md" status="error">
                    <AlertIcon />
                    <AlertDescription>
                      <Text>
                        There was an error logging you in. Please try again.
                      </Text>
                      <Text>This could be because:</Text>
                      <UnorderedList>
                        <ListItem>
                          Your login link was expired. In this case, try logging
                          in again. Make sure that the link you click is the
                          most recent one.
                        </ListItem>
                        <ListItem>
                          Your account was disabled. In this case, contact your
                          administrator.
                        </ListItem>
                      </UnorderedList>
                    </AlertDescription>
                  </Alert>
                )}
              </Box>
            )}
          </Formik>
        ) : (
          <Box backgroundColor="gray.50" borderRadius={5} p={5} mt={5}>
            Check your emails for a login link. If you can&apos;t find it, it
            might be in your spam.
          </Box>
        )}

        <Box mt="4">
          <Flex justify="end">
            <SupportModal>
              <Text fontSize="xs">
                Having trouble logging in? Click here to contact support.
              </Text>
            </SupportModal>
          </Flex>
        </Box>
      </Container>
    </Card>
  );
};
