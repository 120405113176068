import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface CardProps {
  children?: React.ReactNode;
  backgroundColor?: string;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}

export const Card = ({
  children,
  backgroundColor,
  ref,
  ...rest
}: CardProps & BoxProps) => {
  return (
    <Box
      ref={ref}
      boxShadow="md"
      borderRadius={8}
      backgroundColor={backgroundColor ?? "yaarnCream.100"}
      padding={5}
      my={3}
      {...rest}
    >
      {children}
    </Box>
  );
};
