import { initContract } from "@ts-rest/core";
import { z } from "zod";
const c = initContract();

export const adminAuthContract = c.router({
  getToken: {
    method: "GET",
    path: "/get-admin-token/:token",
    pathParams: z.object({
      token: z.string(),
    }),
    responses: {
      200: z.object({}),
    },
  },
  authenticate: {
    method: "POST",
    path: "/authenticate/admin",
    body: z.object({
      token: z.string(),
    }),
    responses: {
      200: z.object({
        admin: z.object({
          id: z.string(),
          email: z.string(),
          name: z.string(),
        }),
        successful: z.boolean(),
      }),
      401: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
    },
  },
  login: {
    method: "POST",
    path: "/login/admin",
    body: z.object({
      email: z.string().email(),
    }),
    responses: {
      200: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
      400: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
    },
  },
});
