import { Box } from "@chakra-ui/react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import "./chatMarkdown.css";

interface ChatbotMarkdownProps {
  message: string;
}

export const ChatbotMarkdown = ({ message }: ChatbotMarkdownProps) => {
  return (
    <Box className="chat-message">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{message}</ReactMarkdown>
    </Box>
  );
};
