import { z } from "zod";
import {
  CoverageCategory,
  CoverageType,
  CoverageMedium,
  PublicationTier,
} from "../graphql";

enum Filter {
  All = "ALL",
}

export type CoverageTypeFilter = Filter | CoverageType;

export const coverageTypeFilter = {
  ...Filter,
  ...CoverageType,
};

export const coverageTypeToString: { [key in CoverageTypeFilter]: string } = {
  ALL: "All types",
  EARNED: "Earned",
  ORGANIC: "Organic",
  PAID: "Paid",
  INBOUND: "Inbound",
};

export const exportFilterOptions = z.object({
  title: z.string().optional().nullable(),
  categories: z.array(z.nativeEnum(CoverageCategory)).optional().nullable(),
  mediums: z.array(z.nativeEnum(CoverageMedium)).optional().nullable(),
  type: z.nativeEnum(coverageTypeFilter).optional().nullable(),
  publication: z.string().optional().nullable(),
  publicationTier: z.nativeEnum(PublicationTier).optional().nullable(),
  dateRange: z.tuple([
    z.date({ coerce: true }).optional().nullable(),
    z.date({ coerce: true }).optional().nullable(),
  ]),
});

export type CoverageTrackerFilterType = z.infer<typeof exportFilterOptions>;
