import { Icon, Link, List, ListItem } from "@chakra-ui/react";
import { WiStars } from "react-icons/wi";

interface SuggestedPromptsProps {
  suggestedPrompts: string[];
  onSuggestedPromptClick: (prompt: string) => void;
}

export const SuggestedPrompts = ({
  suggestedPrompts,
  onSuggestedPromptClick,
}: SuggestedPromptsProps) => {
  return (
    <List
      color="gray.600"
      backgroundColor="gray.200"
      pb="4"
      px="12"
      fontSize="sm"
    >
      {suggestedPrompts.map((prompt) => (
        <ListItem key={prompt}>
          <Link
            textDecor="underline"
            onClick={() => onSuggestedPromptClick(prompt)}
          >
            <Icon mr="4" as={WiStars} />
            {prompt}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
