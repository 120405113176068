import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { UserSchema } from "./signupContract";

const c = initContract();

export const fileContract = c.router({
  upload: {
    method: "POST",
    path: "/upload-files",
    body: z.object({
      clientId: z.string().optional(),
    }),
    contentType: "multipart/form-data",
    responses: {
      200: z.object({
        message: z.string(),
        successful: z.boolean(),
        fileMeta: z
          .object({
            id: z.string(),
            name: z.string(),
            uploadedBy: UserSchema.optional().nullable(),
            mime: z.string(),
            lastModified: z.date(),
            size: z.number(),
            thumb: z.string().optional().nullable(),
          })
          .array(),
      }),
      400: z.object({
        message: z.string(),
        successful: z.boolean(),
      }),
    },
  },
});
