import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  LinkBox,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { format, formatDistance } from "date-fns";
import { FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Card } from "../Card";
import {
  MentionedUser,
  RenderMentions,
} from "components/forms/mentions-field/RenderMentions";

interface NotificationTrayItemProps {
  id: string;
  read: boolean;
  imageUrl?: string | null;
  title: string;
  users: MentionedUser[];
  body: string;
  createdAt: Date;
  link: string;
  onDelete(): void;
}

export const NotificationTrayItem = ({
  read,
  imageUrl,
  title,
  users,
  body,
  createdAt,
  link,
  onDelete,
}: NotificationTrayItemProps) => {
  return (
    <Link to={"/" + link}>
      <LinkBox>
        <Card
          padding="2"
          transition={"ease 500ms all"}
          backgroundColor={read ? "white" : "orange.200"}
        >
          <Flex justify="space-between" align="center">
            <Flex>
              <Box w="24" mr="4">
                {imageUrl ? (
                  <Flex h="100%" align="center" justify="center">
                    <Image
                      borderRadius="9999"
                      w="48px"
                      h="48px"
                      src={process.env.REACT_APP_FILE_URL! + imageUrl}
                    />
                  </Flex>
                ) : (
                  <Flex h="100%" align="center" justify="center">
                    <Icon as={FaBell} color="gray.400" h="60%" w="60%" />
                  </Flex>
                )}
              </Box>
              <Flex
                w="100%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Text fontWeight="semibold" as="h3" fontSize="md">
                  {title}
                </Text>
                <Text fontSize="sm">
                  <RenderMentions content={body} mentionedUsers={users} />
                </Text>
                <Tooltip label={format(createdAt, "dd/MM/yyyy hh:mm aaa")}>
                  <Text fontWeight="semibold" as="small">
                    • {formatDistance(createdAt, new Date())} ago
                  </Text>
                </Tooltip>
              </Flex>
            </Flex>
            <Button
              colorScheme="green"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDelete();
              }}
              size="xs"
              alignSelf="start"
            >
              <CheckIcon />
            </Button>
          </Flex>
        </Card>
      </LinkBox>
    </Link>
  );
};
