import { CopyIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Flex,
  ListItem,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "hooks/useAuth";
import { ChatbotMarkdown } from "./ChatbotMarkdown";
import botLogo from "./bot-logo.png";

interface ChatbotMessageProps {
  message: string;
  from: "user" | "assistant";
}

export const ChatbotMessage = ({ message, from }: ChatbotMessageProps) => {
  const { name, imageUrl } = useAuth();
  const toast = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message);
    toast({
      title: "Copied to clipboard",
      status: "info",
      duration: 2000,
      isClosable: true,
      icon: <CopyIcon />,
    });
  };

  return (
    <ListItem backgroundColor={from === "assistant" ? "gray.200" : "white"}>
      <Flex justify="space-between" py="3" align="center">
        <Flex w="100%">
          <Flex flexGrow="0" mx="4">
            {from === "user" ? (
              <Tooltip label={name}>
                <Avatar
                  size="sm"
                  name={name}
                  src={process.env.REACT_APP_FILE_URL + imageUrl}
                />
              </Tooltip>
            ) : (
              <Tooltip label="Yaarn Bot">
                <Avatar size="sm" name="Yaarn Bot" src={botLogo} />
              </Tooltip>
            )}
          </Flex>
          <ChatbotMarkdown message={message} />
        </Flex>
        <Flex justify="center" flexGrow={0} h="100%" w="6em" alignItems="start">
          {from === "assistant" && (
            <Tooltip label="Copy to clipboard">
              <Button
                ml="2"
                size="xs"
                color="gray.500"
                textAlign="right"
                variant="ghost"
                onClick={copyToClipboard}
              >
                <CopyIcon />
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </ListItem>
  );
};
