import React from "react";
import { Text, VStack } from "@chakra-ui/react";
import reactStringReplace from "react-string-replace";
import { UserPopover } from "../../chat/UserPopover";

export interface MentionedUser {
  id: string;
  bio: string;
  timezone: string;
  imageUrl?: string | null;
  lastOnlineAt: Date;
}

interface RenderMentionsProps {
  content: string;
  mentionedUsers: MentionedUser[];
}

export const RenderMentions: React.FC<RenderMentionsProps> = ({
  content,
  mentionedUsers,
}) => {
  const renderLine = (line: string) => {
    const tags = line.match(/@\[[^\]]*\]\([a-z0-9-]*\)/g);

    let result: string | React.ReactNode[] = line;
    if (tags && tags.length > 0) {
      for (const tag of tags) {
        result = reactStringReplace(result, tag, (m, i) => {
          const firstSegment = m.match(/@\[[^\]]*\]/g)![0];
          const id = m.replace(firstSegment, "").replace(/[()]/g, "");
          const name = firstSegment.replace(/[[\]@]/g, "");

          const user = mentionedUsers.find((m) => m.id === id);

          if (!user) {
            return (
              <Text as="span" color="red" key={i}>
                @USER NOT FOUND!
              </Text>
            );
          }

          return (
            <UserPopover
              key={i}
              author={name}
              lastOnlineAt={new Date(user.lastOnlineAt)}
              bio={user?.bio}
              image={user.imageUrl}
              timezone={user.timezone}
            >
              <Text
                cursor="pointer"
                as="span"
                backgroundColor="blue.100"
                _hover={{
                  backgroundColor: "blue.200",
                }}
                transition="ease 200ms all"
                p="0.5"
                borderRadius="lg"
                fontWeight="semibold"
              >
                @{name}
              </Text>
            </UserPopover>
          );
        });
      }
    }

    return result;
  };

  const lines = content.split("\n");

  return (
    <VStack align="stretch" spacing={1}>
      {lines.map((line, index) => (
        <Text key={index}>{renderLine(line)}</Text>
      ))}
    </VStack>
  );
};
