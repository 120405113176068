import { Flex, Tooltip, Avatar, Box } from "@chakra-ui/react";
import { ChatbotDrawer, ChatbotMessageType } from "./chatbot/ChatbotDrawer";
import { createContext, useState } from "react";
import botLogo from "./chatbot/bot-logo.png";

interface ChatbotProviderProps {
  children: React.ReactNode;
}

export interface ChatbotContextProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setMessages: React.Dispatch<React.SetStateAction<ChatbotMessageType[]>>;
}

export const ChatbotContext = createContext<ChatbotContextProps>({
  isOpen: false,
  setIsOpen: () => undefined,
  setMessages: () => undefined,
});

export const ChatbotProvider = ({ children }: ChatbotProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<ChatbotMessageType[]>([]);

  return (
    <ChatbotContext.Provider value={{ isOpen, setMessages, setIsOpen }}>
      {children}
      <ChatbotDrawer
        messages={messages}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <Flex position="fixed" bottom="0" right={0} justify="end" align="center">
        <Tooltip label="Open the Yaarn Bot">
          <Box
            _hover={{
              transform: "scale(1.05)",
              filter: "brightness(1.1)",
            }}
            mb="6"
            backgroundColor="yaarnRed.500"
            transition="all 0.2s ease-in-out"
            cursor="pointer"
            borderLeftRadius="md"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <Avatar my="6" ml="2" size="sm" src={botLogo} name="Yaarn Bot" />
          </Box>
        </Tooltip>
      </Flex>
    </ChatbotContext.Provider>
  );
};
