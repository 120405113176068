import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, ListItem, Text } from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import React, { useContext, useState } from "react";
import { IconType } from "react-icons";
import { SidebarFolderContext } from "./SidebarFolderProvider";

interface SidebarLinkFolderProps {
  text: string;
  id: string;
  icon: IconType;
  children?: React.ReactNode | React.ReactNode[];
}

export const SidebarLinkFolder = ({
  text,
  id,
  icon,
  children,
}: SidebarLinkFolderProps) => {
  const [parent] = useAutoAnimate();
  const context = useContext(SidebarFolderContext);
  const [isExpanded, setIsExpanded] = useState(context.folders.includes(id));

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    isExpanded
      ? (context.folders = context.folders.filter((f) => f !== id))
      : context.folders.push(id);
  };

  if (
    !children ||
    (Array.isArray(children) && children.filter((x) => !!x).length === 0)
  ) {
    return <></>;
  }

  return (
    <>
      <ListItem onClick={toggleExpanded} my="1" mx="4">
        <Box
          transition="ease 400ms all"
          cursor="pointer"
          backgroundColor={isExpanded ? "yaarnRed.400" : undefined}
          _hover={{
            backgroundColor: "yaarnRed.400",
          }}
          py={3}
          px={3}
          borderRadius="md"
        >
          <Flex align="center" justifyContent="space-between">
            <Flex color={"white"}>
              <Box mr={3} display="inline">
                <Icon fontSize="sm" as={icon} />
              </Box>
              <Box fontWeight={"regular"} display="inline">
                <Text>{text}</Text>
              </Box>
            </Flex>
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Flex>
        </Box>
      </ListItem>
      <Flex ref={parent}>
        {isExpanded && (
          <>
            <Box
              my="2"
              transform="translateX(18px)"
              w="2px"
              backgroundColor="gray.300"
            />
            <Box w="100%" ml="2">
              {children}
            </Box>
          </>
        )}
      </Flex>
    </>
  );
};
