import { EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl, TextareaControl, SubmitButton } from "formik-chakra-ui";
import { requestSupportEmail } from "services/requestSupportEmail";
import * as Yup from "yup";

interface SupportModalProps {
  children: React.ReactNode;
  initialValues?: {
    name: string;
    email: string;
  };
}

export const SupportModal = ({
  children,
  initialValues,
}: SupportModalProps) => {
  const { onClose, onOpen, isOpen } = useDisclosure();

  const initVals = {
    name: initialValues?.name || "",
    email: initialValues?.email || "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("You must enter a name."),
    email: Yup.string()
      .required("You must enter an email.")
      .email("The email must be valid."),
    message: Yup.string().required("You must enter a message."),
  });

  const toast = useToast();

  const handleSubmit = async (values: typeof initVals) => {
    try {
      await requestSupportEmail(values.email, values.name, values.message);
      toast({
        title: "Support request sent",
        description:
          "Your support request has been sent. We will get back to you as soon as possible.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Couldn't send support request",
        description:
          "There was an error sending the support request. Please send an email manually to contact@yaarn.ai.",
        status: "error",
        duration: 20000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box _hover={{ textDecor: "none" }} onClick={onOpen}>
        {children}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Support</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={initVals}
            >
              {({ isSubmitting }) => (
                <Form>
                  {!initialValues && (
                    <>
                      <InputControl mt="3" name="name" label="Name" />
                      <InputControl mt="3" name="email" label="Email" />
                    </>
                  )}
                  <TextareaControl mt="3" name="message" label="Message" />
                  <Flex justify="end">
                    <SubmitButton
                      colorScheme="yaarnRed"
                      leftIcon={<EmailIcon />}
                      mt={4}
                      isLoading={isSubmitting}
                    >
                      Send message
                    </SubmitButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
