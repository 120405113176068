import { InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, ListItem, Text, Tooltip } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface SidebarLinkProps {
  text: string;
  icon: IconType;
  label: React.ReactNode;
  fontSize?: string;
}

export const SidebarComingSoon = ({
  text,
  icon,
  label,
  fontSize,
}: SidebarLinkProps) => {
  return (
    <ListItem my="1" mx="4">
      <Box
        fontSize={fontSize}
        userSelect="none"
        transition="ease 400ms all"
        cursor="not-allowed"
        backgroundColor="yaarnRed.400"
        py={3}
        px={3}
        borderRadius="md"
        color="gray.300"
      >
        <Flex align="center" justify="space-between">
          <Flex>
            <Box mr={3} display="inline">
              <Icon fontSize="sm" color={"gray.300"} as={icon} />
            </Box>
            <Box fontWeight={"regular"} display="inline">
              <Text>{text}</Text>
            </Box>
          </Flex>
          <Tooltip label={label}>
            <InfoIcon />
          </Tooltip>
        </Flex>
      </Box>
    </ListItem>
  );
};
